<template>
  <div>
    <div class="input-group mb-3">
      <input type="text" v-model="currentEmail" placeholder="Enter email" class="form-control" @keyup.enter="addEmail" />
      <button @click="addEmail" class="btn btn-outline-primary">Add Email</button>
    </div>

    <div v-if="emails.length" class="mt-3">
      <h5>Email List:</h5>
      <ul class="list-group mb-3">
        <li v-for="(email, index) in emails" :key="index" class="list-group-item d-flex justify-content-between align-items-center" :class="{ 'list-group-item-success': defaultEmailSet.includes(email) }">
          <span>{{ email }} <span v-if="defaultEmailSet.includes(email)" class="ms-2 text-success"><i class="fas fa-star"></i> Default</span></span>
          <div>
            <button v-if="!defaultEmailSet.includes(email)" @click="markAsDefault(email)" class="btn btn-outline-success btn-sm me-2">Mark Default</button>
            <button v-if="defaultEmailSet.includes(email)" @click="removeDefault(email)" class="btn btn-outline-danger btn-sm me-2">Remove Default</button>
            <button v-if="!defaultEmailSet.includes(email)" @click="removeEmail(index)" class="btn btn-outline-danger btn-sm">Delete</button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { useMutation } from '@urql/vue';
import gql from 'graphql-tag';

const MARK_DEFAULT_EMAIL = gql`
  mutation markDefaultEmail($professionalId: String!, $email: String!) {
    upsertWeeklyemailsDefaultEmail(professionalId: $professionalId, email: $email)
  }
`;

const REMOVE_DEFAULT_EMAIL = gql`
  mutation removeDefaultEmail($professionalId: String!, $email: String!) {
    deleteWeeklyemailsDefaultEmail(professionalId: $professionalId, email: $email)
  }
`;

export default {
  props: {
    initialEmails: { type: Array, default: () => [] },
    defaultEmails: { type: String, default: '' },
    professionalId: { type: String, required: true }
  },
  setup(props, { emit }) {
    const emails = ref([...props.initialEmails]);
    const currentEmail = ref('');

    const { executeMutation: markDefaultEmail } = useMutation(MARK_DEFAULT_EMAIL);
    const { executeMutation: removeDefaultEmail } = useMutation(REMOVE_DEFAULT_EMAIL);

    //const defaultEmailSet = computed(() => props.defaultEmails.split(',').map(email => email.trim()));
    const defaultEmailSet = computed(() =>
      props.defaultEmails
        .split(',')
        .map(email => email.trim())
        .filter(email => email) // Remove empty strings
    );
    //console.log("defaultEmailSet::",defaultEmailSet.value);
    const addEmail = () => {
      const trimmedEmail = currentEmail.value.trim().toLowerCase();
      if (trimmedEmail && isValidEmail(trimmedEmail) && !emails.value.includes(trimmedEmail)) {
        emails.value.push(trimmedEmail);
        currentEmail.value = '';
        updateEmailOutput();
      }
    };

    const removeEmail = (index) => {
      emails.value.splice(index, 1);
      updateEmailOutput();
    };

    const updateEmailOutput = () => {
      const emailOutput = emails.value.join(', ');
      emit('update-emails', emailOutput);
    };

    const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

    const markAsDefault = async (email) => {
      const variables = { professionalId: props.professionalId, email };
      try {
        const { data, error } = await markDefaultEmail(variables);
        console.log('GraphQL Response:', data, error);

        if (data?.upsertWeeklyemailsDefaultEmail) {
          const updatedDefaults = [...defaultEmailSet.value, email]; // Update defaults
          emit('update-default-email', updatedDefaults.join(', ')); // Notify parent
          console.log("MARKING Add DEFAULT...", updatedDefaults);
        } else {
          console.error('GraphQL Error:', error || 'Unexpected response format.');
        }
      } catch (err) {
        console.error('Error in markAsDefault:', err);
      }
    };

    const removeDefault = async (email) => {
      const variables = { professionalId: props.professionalId, email };
      try {
        const { data, error } = await removeDefaultEmail(variables);
        console.log('GraphQL Response:', data, error);

        if (data?.deleteWeeklyemailsDefaultEmail === true) { // Check explicitly for `true`
          const updatedDefaults = defaultEmailSet.value.filter(defaultEmail => defaultEmail !== email); // Remove email
          emit('update-default-email', updatedDefaults.join(', ')); // Notify parent
          console.log("Removed default email:", email);
        } else {
          console.error('Unexpected response or failed operation:', data);
        }
      } catch (err) {
        console.error('Error in removeDefault:', err);
      }
    };


    return { emails, currentEmail, addEmail, removeEmail, markAsDefault, removeDefault, defaultEmailSet };
  }
};
</script>
