import gql from 'graphql-tag';

export const GET_MY_FIRMS = gql`
  query GetMyFirms($username: String!) {
    appFirmSearchCounts(username: $username) {
      id
      name
      totalCount
    }
  }
`;

export const GET_PROFESSIONALS_BY_FIRM_ID = gql`
  query GetAppProfessionalsByFirmId($firm_id: String!) {
    getAppProfessionalsByFirmId(firmId: $firm_id) {
      id
      name
      email
    }
  }
`;

export const INITIALIZE_FIRM_SEARCH_LIST_RESULTS = gql`
  query InitializeFirmSearchListResults($username: String!) {
    initializeFirmSearchListResults(username: $username)
  }
`;

export const SAVE_SELECTED_PROFESSIONALS_BY_FIRM_ID = gql`
  mutation SaveSelectedProfessionals($firm_id: String!, $search_list_id: String!, $selectedProfessionals: [String!]!, $username: String!) {
    saveSelectedProfessionals(firmId: $firm_id, searchListId: $search_list_id, selectedProfessionals: $selectedProfessionals, username: $username)
  }
`;

export const GET_MY_APP_FIRM_SEARCH_RESULTS = gql`
  query GetMyAppFirmSearchResults($username: String!) {
    appMyFirmSearchResultsLists(username: $username) {
      firmId
      firmName
      records {
        name
        id
        tos
      }
    }
  }
`;

export const APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID = gql`
  query appFirmSearchResultsByFirmId($firm_id: String!) {
    appFirmSearchResultsByFirmId(firmId: $firm_id) {  
      records {
        profileId
        company
        count
        name
        searchFilter
      }
      id
      firmName
    }
  }
`;




export const GET_APP_FIRM_CUSTOM_NAMES_BY_LIST_IDS = gql`
  query getAppFirmCustomNamesByListIds($listIds: [String!]!) {
    getAppFirmCustomNamesByListIds(listIds: $listIds) {
      id
      customName
      firmShortNames
      listId
      firmId
      created
    }
  }
`;

export const SAVE_APP_FIRM_CUSTOM_NAME_SEARCH_LIST = gql`
  mutation saveAppFirmCustomNameSearchList($customName: String!, $listId: String!, $firmId: String!, $firmShortNames: [String!]) {
    saveAppFirmCustomNameSearchList(customName: $customName, listId: $listId, firmId: $firmId, firmShortNames: $firmShortNames) {
      id
      customName
      listId
      firmId
      firmShortNames
      created
    }
  }
`;


export const DELETE_APP_FIRM_CUSTOM_NAME_SEARCH_LIST = gql`
  mutation deleteAppFirmCustomNameSearchList($listId: String!) {
    deleteAppFirmCustomNameSearchList(listId: $listId) 
  }
`;


/*export const APP_FIRM_SEARCH_RESULTS_BY_FIRM_ID = gql`
  query appFirmSearchResultsByFirmId($firm_id: String!) {
    appFirmSearchResultsByFirmId(firmId: $firm_id) {  
      firmName
      userIdToName {
        name {
          fullName
        }
        experienceAggregate {
          currentTitle
          companyName
        }
        id
      }
      searchRecords {
        name
        id
        excludedIds
        searchedParameters {
          executive {
            experience {
              highestTitle
              plSize
            }
            industrySectors
            willingToRelocate
            expertise {
              addonAcquisitionIntegration
              addonAcquisitionIntegrationRoles
              addonAcquisitions
              addonAcquisitionsRoles
              financialRestruct
              financialRestructRoles
              highestLevel
              hyperGrowth
              turnAroundRoles
              turnAround
              sponsored
              salesProcessRoles
              salesProcess
              operationalTurnaroundRoles
              operationalTurnaround
              mergersAcquisitionsRoles
              mergersAcquisitions
              ipoProcessRoles
              ipoProcess
              hyperGrowthRoles
            }
          }
        }
        savedBy {
          userId
          firmId
          role
        }
      }
    }
  }
`;*/