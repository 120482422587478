<template>
  <div class="container mt-5">
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <!-- <div>{{ all_search_list_names }}</div> -->
    <!-- Firm names -->
    <div class="col-12" v-for="(item, index) in serverItems" :key="index" :data-firm-id="item.firmId">
      <h5>{{ item.firmName }}</h5>
      <!-- List of firm records -->
      <div class="row ms-4 mb-2" v-for="(record, recordIndex) in item.records" :key="recordIndex" :data-search-list-id="record.id">
        <div :class="['col-8', record.tos && record.tos.length > 0 ? '' : 'in-active']">
          <p>
            {{ record.name }} | <strong>{{ all_search_list_names[record.id]?.name || 'N/A' }} | {{ all_search_list_names[record.id]?.firmShortNames || 'N/A' }}</strong>  
            &nbsp; 
            <button
              class="btn btn-sm btn-warning"
              @click="
                recordIndex === 0
                  ? openEditCustomNamesModal(item.firmId, record.id, all_search_list_names[record.id])
                  : openEditCustomNamesModal(item.firmId, record.id, all_search_list_names[record.id], false)
              "
            >Change List Name <span v-if="recordIndex === 0"> & Firm Short Name</span></button>
            &nbsp; <button v-if="all_search_list_names[record.id]" class="btn btn-sm btn-danger" @click="deleteListCustomName(record.id)">Delete Name</button>
          </p>
          <p v-if="record.tos && record.tos.length > 0" :id="'tos-' + item.firmId + '-' + record.id">
          <strong>Tos:</strong> 
          <span v-for="(tos, tosIndex) in record.tos" :key="tosIndex">
            {{ tos.split('|@|')[1] }} ({{ tos.split('|@|')[2] }})
            <span v-if="tosIndex < record.tos.length - 1">, </span>
          </span>
        </p>
        <p v-else>In-Active Record</p>
        </div>
        <div :class="['col-4 text-end', record.tos && record.tos.length > 0 ? '' : 'in-active']"><button class="btn btn-sm btn-primary" @click="openModal(item.firmId, record.id, record.tos)">Set To</button></div>
        <hr />
      </div>
    </div>
    <div v-if="loading" class="row mb-3">
      <div class="col-12"><p>Loading...</p></div>
    </div>

    <!-- VueFinalModal to display GetProfessionalByFirmID component -->
    <VueFinalModal v-model="showModal" content-class="custom-modal-content">
      <div>
        <!-- Pass firmId, searchListId, and selectedProfessionals -->
        <GetProfessionalByFirmID 
          :firmId="selectedFirmId" 
          :searchListId="selectedSearchListId"
          :selectedProfessionals="selectedProfessionals"
          :username="username"
          @saveSelectedProfessionals="handleSaveSelectedProfessionals" 
        />
      </div>
    </VueFinalModal>

    <VueFinalModal v-model="showEditCustomNamesModal" content-class="custom-modal-content">
      <div>
        <h5>Edit Record</h5>
        <div class="mb-1">
          <label for="customName" class="form-label">Custom Name</label>
          <input type="text" id="customName" class="form-control" v-model="editCustomNamesForm.customName" />
        </div>
        <div class="mb-1" v-if="editCustomNamesForm.showFirstIteration">
          <label for="firmShortNames" class="form-label">Short Firm Names (comma-separated)</label>
          <input type="text" id="firmShortNames" class="form-control" v-model="editCustomNamesForm.firmShortNames" />
        </div>
        <div class="mt-3">
          <button class="btn btn-primary mr-1" @click="saveCustomNamesEdit">Save</button>&nbsp;<button class="btn btn-secondary" @click="showEditCustomNamesModal = false">Cancel</button>
        </div>
      </div>
    </VueFinalModal>
  </div>
</template>

<script>
import { getCurrentInstance, defineComponent, ref, watchEffect, onMounted } from 'vue';
import { useQuery, useMutation } from '@urql/vue';
import { GET_MY_APP_FIRM_SEARCH_RESULTS, INITIALIZE_FIRM_SEARCH_LIST_RESULTS, GET_APP_FIRM_CUSTOM_NAMES_BY_LIST_IDS, SAVE_APP_FIRM_CUSTOM_NAME_SEARCH_LIST, DELETE_APP_FIRM_CUSTOM_NAME_SEARCH_LIST } from '../graphql/firms_queries';
import GetProfessionalByFirmID from '../components/GetProfessionalByFirmID.vue';
import { VueFinalModal } from 'vue-final-modal';

export default defineComponent({
  name: "AppMyFirms",
  components: {
    GetProfessionalByFirmID,
    VueFinalModal,
  },
  setup() {
    const { appContext } = getCurrentInstance();
    const username = appContext.config.globalProperties.$username;

    const serverItems = ref([]);
    const totalItems = ref(0);
    const loading = ref(true);
    const showModal = ref(false);
    const selectedFirmId = ref(null);
    const selectedSearchListId = ref(null);
    const selectedProfessionals = ref([]);
    const errorMessage = ref('');
    const all_search_list_ids = ref([]);
    const all_search_list_names = ref({});
    const showEditCustomNamesModal = ref(false);
    
    const editCustomNamesForm = ref({
      firmId: null,
      listId: null,
      customName: "",
      firmShortNames: "",
      showFirstIteration: true,
    });

    // Reactive variables for the queries
    const initializeQuery = useQuery({
      query: INITIALIZE_FIRM_SEARCH_LIST_RESULTS,
      variables: { username: username },
      pause: true,
    });

    const firmDataResult = useQuery({
      query: GET_MY_APP_FIRM_SEARCH_RESULTS,
      variables: { username: username },
      pause: true,
    });

    const customSearchListQueryVariables = ref({
      listIds: all_search_list_ids.value
    });
    const CustomSearchListNamesQuery = useQuery({
      query: GET_APP_FIRM_CUSTOM_NAMES_BY_LIST_IDS,
      variables: customSearchListQueryVariables.value,
      pause: true,
    });
    const loadCustomSearchListNames = async () => {
      customSearchListQueryVariables.value.listIds = all_search_list_ids.value;
      CustomSearchListNamesQuery.executeQuery();
      watchEffect(() => {
        if (CustomSearchListNamesQuery.data.value) {
          const searchListData = CustomSearchListNamesQuery.data.value.getAppFirmCustomNamesByListIds;
          all_search_list_names.value = searchListData.reduce((acc, item) => {
            acc[item.listId] = {
              name: item.customName || "N/A", // Ensure customName is set
              firmShortNames: (item.firmShortNames || []).join(", "), // Convert array to comma-separated string
            };
            return acc;
          }, {});
        }
      });
    };
    // Function to initialize firm search list results
    const initializeFirmSearchListResults = async () => {
      const { data, error } = await initializeQuery.executeQuery();
      if (data.value) {
        const message = data.value.initializeFirmSearchListResults;
        if (message === "Success: Records updated.") {
          //console.log("Initialization successful");
          loadItems();
        } else {
          errorMessage.value = 'Initialization failed, refresh page again: ' + message;
        }
      }
      if (error.value) {
        errorMessage.value = 'Error during initialization: ' + error.value.message;
      }
    };
    // Function to load firm search results
    const loadItems = async () => {
      loading.value = true;
      firmDataResult.executeQuery();

      watchEffect(() => {
        if (firmDataResult.data.value) {
          serverItems.value = firmDataResult.data.value.appMyFirmSearchResultsLists;
          totalItems.value = serverItems.value.length;
          loading.value = false;
        }

        if (firmDataResult.error.value) {
          errorMessage.value = 'Error fetching data: ' + firmDataResult.error.value.message;
          loading.value = false;
        }

        all_search_list_ids.value = serverItems.value.flatMap(item => item.records.map(record => record.id));
        loadCustomSearchListNames();
      });
    };

    // Using destructuring to rename executeMutation
    const { executeMutation: executeSaveMutation } = useMutation(SAVE_APP_FIRM_CUSTOM_NAME_SEARCH_LIST);
    const { executeMutation: executeDeleteMutation } = useMutation(DELETE_APP_FIRM_CUSTOM_NAME_SEARCH_LIST);

    // Function to change the custom name
    const openEditCustomNamesModal = (firmId, listId, currentData, showFirstIteration = true) => {
      editCustomNamesForm.value.firmId = firmId;
      editCustomNamesForm.value.listId = listId;
      editCustomNamesForm.value.customName = currentData?.name || "";
      editCustomNamesForm.value.firmShortNames = currentData?.firmShortNames || "";
      editCustomNamesForm.value.showFirstIteration = showFirstIteration;
      showEditCustomNamesModal.value = true;
    };

    const saveCustomNamesEdit = async () => {
      const { firmId, listId, customName, firmShortNames } = editCustomNamesForm.value;
      const processedfirmShortNames = firmShortNames.split(",").map(name => name.trim()).filter(Boolean).join(", ");
      const result = await executeSaveMutation({
        customName,
        listId,
        firmId,
        firmShortNames: processedfirmShortNames,
      });

      if (result.data && result.data.saveAppFirmCustomNameSearchList) {
        all_search_list_names.value[listId] = {
          name: customName,
          firmShortNames,
        };
        console.log("Changes saved successfully");
        showEditCustomNamesModal.value = false;
      } else {
        errorMessage.value = `Failed to save changes: ${result.error.message}`;
      }
    };
    /*
    const changeListCustomName = async (listId, firmId) => {
      const newName = prompt("Enter the new name:"); // Prompt for the new name
      const firmShortNames = prompt("Enter the firm short names (comma-separated):");
      if (newName || firmShortNames) {
        const result = await executeSaveMutation({ 
          customName: newName, 
          listId: listId, 
          firmId: firmId,
          firmShortNames: firmShortNames 
        });
        if (result.data && result.data.saveAppFirmCustomNameSearchList) {
          // Update the local state
          //all_search_list_names.value[listId] = newName;
          all_search_list_names.value[listId] = { name: newName, firmShortNames: firmShortNames };
          console.log("Name changed successfully");
        } else {
          console.error("Error changing name and short names:", result.error);
          errorMessage.value = "Failed to change name and short names: " + result.error.message;
        }
      }
    };
    */

    // Function to delete the custom name
    const deleteListCustomName = async (listId) => {
      const confirmDelete = confirm("Are you sure you want to delete this name and short names?");
      if (confirmDelete) {
        const result = await executeDeleteMutation({ 
          listId: listId 
        });
        if (result.data && result.data.deleteAppFirmCustomNameSearchList) {
          delete all_search_list_names.value[listId]; // Remove the name from the local state
          console.log("Name deleted successfully");
        } else {
          console.error("Error deleting name:", result.error);
          errorMessage.value = "Failed to delete name: " + result.error.message;
        }
      }
    };

    const openModal = (firmId, searchListId, professionals) => {
      selectedFirmId.value = firmId;
      selectedSearchListId.value = searchListId;
      selectedProfessionals.value = professionals || [];
      showModal.value = true;
    };

    const handleSaveSelectedProfessionals = (selectedProfessionals) => {
      const item = serverItems.value.find(item => item.firmId === selectedFirmId.value);
      if (item) {
        const record = item.records.find(record => record.id === selectedSearchListId.value);
        if (record) {
          record.tos = selectedProfessionals.map(prof => prof);
        }
        /*console.log("record", record);
        console.log("record.tos", record.tos);*/
      }
      /*console.log("item", item);
      console.log("selectedFirmId.value", selectedFirmId.value);
      console.log("selectedSearchListId.value", selectedSearchListId.value);*/
      showModal.value = false;
    };

    onMounted(() => {
      initializeFirmSearchListResults(); // Execute the initialization when the component is mounted
    });

    return {
      serverItems,
      totalItems,
      loading,
      showModal,
      selectedFirmId,
      selectedSearchListId,
      selectedProfessionals,
      openModal,
      handleSaveSelectedProfessionals,
      username,
      errorMessage,
      all_search_list_names,
      //changeListCustomName,
      deleteListCustomName,
      showEditCustomNamesModal,
      editCustomNamesForm,
      openEditCustomNamesModal,
      saveCustomNamesEdit
    };
  },
});
</script>
<style scoped>
  hr {
    height: 1px;
  }
  .in-active {
    background-color: gainsboro; /* Light red background */
    color: #721c24; /* Dark red text */
  }
</style>
